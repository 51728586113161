/*@jsxRuntime classic @jsx React.createElement @jsxFrag React.Fragment*/
import {useMDXComponents as _provideComponents} from "@mdx-js/react";
import React from "react";
function _createMdxContent(props) {
  const _components = Object.assign({
    p: "p",
    ul: "ul",
    li: "li",
    a: "a"
  }, _provideComponents(), props.components);
  return React.createElement(React.Fragment, null, React.createElement(_components.p, null, "Siempre está bien comprender como funcionan las cosas que usamos en el día a día, tanto para el informativo como para el electrónico los depuradores son de gran utilidad, pero: ¿Que hay detrás de ellos? ¿Como funcionan?"), "\n", React.createElement(_components.p, null, "La respuestas a estas preguntas nos la proporciona la siguiente serie de post de Genbeta Dev:"), "\n", React.createElement(_components.ul, null, "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "http://www.genbetadev.com/cc/como-funciona-un-depurador-de-c-c-parte-i"
  }, "¿Cómo funciona un depurador de C/C++? (Parte I)")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "http://www.genbetadev.com/cc/como-funciona-un-depurador-de-c-c-parte-ii"
  }, "¿Cómo funciona un depurador de C/C++? (Parte II)")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "http://www.genbetadev.com/cc/como-funciona-un-depurador-de-c-c-parte-iii"
  }, "¿Cómo funciona un depurador de C/C++? (Parte III)")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "http://www.genbetadev.com/cc/como-funciona-el-breakpoint-en-un-depurador-de-c-c"
  }, "¿Cómo funciona el breakpoint en un depurador de C/C++?")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "http://www.genbetadev.com/cc/como-funciona-el-hardware-breakpoint-en-un-depurador-de-c-c-breakpoints-part-ii"
  }, "¿Cómo funciona el hardware breakpoint en un depurador de C/C++?")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "http://www.genbetadev.com/cc/como-funciona-el-memory-breakpoint-en-un-depurador-de-c-c-breakpoints-part-iii"
  }, "¿Cómo funciona el memory breakpoint en un depurador de C/C++?")), "\n"), "\n", React.createElement(_components.p, null, "Las explicaciones están particularizadas para los procesadores x86 y x86_64 y para el depurador de C/C++, pero aún así esto se puede extrapolar al resto de plataformas."));
}
function MDXContent(props = {}) {
  const {wrapper: MDXLayout} = Object.assign({}, _provideComponents(), props.components);
  return MDXLayout ? React.createElement(MDXLayout, props, React.createElement(_createMdxContent, props)) : _createMdxContent(props);
}
export default MDXContent;
